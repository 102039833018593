@font-face {
  font-family: "Futura-Light";
  src: local("Futura-Light"), url(./assets/fonts/futura-light.woff) format("woff");
}

@font-face {
  font-family: "Futura";
  src: local("Futura"), url(./assets/fonts/futura-regular.woff) format("woff");
}

@font-face {
  font-family: "Futura-Medium";
  src: local("Futura-Medium"), url(./assets/fonts/futura-medium.woff) format("woff");
}

@font-face {
  font-family: "Futura-Bold";
  src: local("Futura-Bold"), url(./assets/fonts/futura-bold.woff) format("woff");
}
$enable-responsive-font-sizes: true;
$font-size-base: 0.9rem;
$body-bg: #000;
$body-color: #c8c8c8;
$primary: #fff301;
$secondary: #c8c8c8;
$headings-color: #c8c8c8;
$font-family-base: "Futura-Light", -apple-system, sans-serif;
$headings-font-family: "Futura-Medium", -apple-system, sans-serif;
$font-size-h1: 24px;
$link-color: $secondary;
$link-hover-color: $primary;
$btn-link-hover-color: $primary;
$link-hover-decoration: none;
$table-accent-bg: #000;
$table-border-color: none;
$dark: #1a1a1a;

$modal-content-bg: $dark;
$modal-content-border-color: $secondary;

@import "~bootstrap/scss/bootstrap.scss";

.table-striped > tbody > tr:nth-child(2n) > td,
.table-striped > tbody > tr:nth-child(2n) > th {
  background-color: #1a1a1a;
}

body,
html {
  margin: 0;
  padding: 0;
}
* {
  box-sizing: border-box;
}

.freq-sho-medium {
  font-family: "Futura-Medium", -apple-system, sans-serif;
}

.freq-sho-light {
  font-family: "Futura-Light", -apple-system, sans-serif;
}

.freq-sho-bold {
  font-family: "Futura-Bold", -apple-system, sans-serif;
}

.freq-sho-list-title {
  font-size: 1.2em;
}

.dropdown-toggle::after {
  margin-left: 10px;
  vertical-align: 0.2em;
}

.freq-sho-data-table {
  position: relative;
  width: 100%;
  height: calc(100vh - 320px);
  overflow: auto;
}

thead tr:nth-child(1) th {
  background: #3a3a3a;
  position: sticky;
  top: 0;
  z-index: 10;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}

::-webkit-scrollbar-thumb {
  background-color: #c8c8c8;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #fff301;
}

.page-link {
  background-color: #000;
  color: #fff;
}

.page-item.active .page-link {
  color: #000;
}

.page-link:hover {
  color: #000;
}

@media screen and (min-width: 1px) {
  .pagination {
    justify-content: center;
    font-size: 1.2em;
  }
}

@media screen and (min-width: 992px) {
  .pagination {
    justify-content: flex-end;
    font-size: 1em;
  }
}

.multi-select {
  color: #000;
}

.loading-overlay {
  position: absolute;
  height: calc(100vh - 320px);
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999999;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px) saturate(65%);
}

.mobile-loading-overlay {
  background: rgba(0, 0, 0, 0.3);
  filter: blur(2px) saturate(65%);
  z-index: 999999;
}

.data-list-spinner {
  position: absolute;
  inset: 0;
  top: 210px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (min-width: 1px) {
  .fs-modal {
    margin: 0 auto;
    max-width: 1500px !important;
    .close {
      position: absolute;
      top: 15px;
      right: 18px;
      color: #fff;
      font-size: 2.3rem;
    }
    .modal-content {
      width: 95%;
      height: 90vh;
    }
    h1 a {
      color: #fff301;
    }
    h1 a:hover {
      color: #ddd207;
    }
  }
  .fs-rating-modal-header {
    height: 95px;
    padding: 0.25rem;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .fs-ratings-video-player {
    height: 150px;
    margin-bottom: 0.5rem;
  }
  .fs-ratings-video-select {
    height: 30px;
    overflow-x: auto;
    overflow-y: hidden;
    white-space: nowrap;
    div {
      display: inline-block;
    }
  }
}

@media (min-width: 768px) {
  .fs-ratings-video-player {
    height: 300px;
  }
}

@media (min-width: 1200px) {
  .fs-modal {
    .modal-content {
      width: 95%;
      height: 80vh;
      max-height: 800px !important;
    }
  }
  .fs-rating-modal-header {
    min-height: 150px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }
  .fs-ratings-video-select {
    display: block;
    height: 60vh;
    max-height: 420px;
    overflow-x: hidden;
    overflow-y: auto;
    div {
      display: block;
    }
  }
  .fs-ratings-video-player {
    height: 60vh;
    max-height: 420px;
  }
}

.fs-desktop-nav-items {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}

.active-thumbnail-video {
  border-width: 5px !important;
  border-color: #fff301 !important;
}

.inactive-thumbnail-video {
  border: none;
  border-color: #383838 !important;
}

.fs-marquee-item {
  white-space: nowrap;
}

.ratings-sort-btn:hover {
  background: #fff301;
  border-color: #fff301;
}

.pointer-events-auto {
  pointer-events: auto;
}

.pointer-events-none {
  pointer-events: none;
}

@media (min-width: 1px) {
  .no-content {
    width: 100%;
    padding: 4rem 1rem;
    font-size: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (min-width: 728px) {
  .no-content {
    padding-top: 7rem;
    font-size: 2rem;
  }
}

.primary-loading {
  padding-top: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
}
