.container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* position: absolute; */
    top: 0;
    left: 0;
    right: 0;
    background-color: #000;
    height: 60px;
    padding: 20px 0;
    z-index: 200;
}

.logo {
    width: 300px;
}

.siteTitle {
    font-size: 3.5em;
    text-transform: uppercase;
    margin: 30px;
}

.links {
    font-size: 1.5em;
}

.links>* {
    margin: 0 20px;
}